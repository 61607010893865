import React from "react";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="text-gray-600 bg-gray-100 body-font">
            <div className="container px-5 py-8 mx-auto flex items-center md:flex-row flex-col">
                <div className="flex items-center md:items-start flex-col">
                    <Link className="flex title-font font-medium items-center justify-start text-gray-900" to="/">
                        <img src="/logo_round.svg" alt="logo" />
                        <span className="ml-3 text-xl">Mayan.Host</span>
                    </Link>
                    <p className="text-sm text-gray-500 sm:py-2 mt-0 text-left">
                        © Samyojaka Software (OPC) Private Limited 2021
                    </p>
                </div>
                <div className="flex-grow flex flex-wrap lg:pl-10 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div className="md:w-full w-full px-4">
                        <nav className="list-none mb-10">
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#aboutus">About Us</a>
                            </li>
                            <li>
                                <Link className="text-gray-600 hover:text-gray-800" to='/contact'>Contact Us</Link>
                            </li>
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#sla">Service Level Agreement</a>
                            </li>
                        </nav>
                    </div>
                </div>
                <div className="flex-grow flex flex-wrap -mb-9 md:mt-0 mt-5 md:text-left text-center">
                    <div className="md:w-full w-full px-4">
                        <nav className="list-none mb-10">
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#tos">Terms of Service</a>
                            </li>
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#privacypolicy">Privacy Policy</a>
                            </li>
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#refundpolicy">Refund Policy</a>
                            </li>
                            <li>
                                <a className="text-gray-600 hover:text-gray-800" href="#policyfaq">Policy FAQ</a>
                            </li>
                        </nav>
                    </div>
                </div>
                <span className="inline-flex lg:ml-auto mt-4 mt-0 justify-center sm:justify-start px-4">
                    <a className="text-gray-500" href="#facebook">
                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
                        </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="#twitter">
                        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
                        </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="#youtube">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1.25rem" width="1.25rem" viewBox="15.20005 -41.33325 205.0671 247.9995"><path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.747-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.554 7.652 7.602 15.655 4.904 25.817 0 44.237 0 82.667 0 82.667s0 38.43 4.904 56.85c2.698 10.162 10.65 18.164 20.747 20.881 18.3 4.935 91.682 4.935 91.682 4.935s73.383 0 91.683-4.935c10.097-2.717 18.048-10.72 20.747-20.88 4.904-18.422 4.904-56.851 4.904-56.851s0-38.43-4.904-56.85" fill="currentColor" />
                            <path d="M93.333 117.558l61.334-34.89-61.334-34.893z" fill="#fff" />
                        </svg>
                    </a>
                    <a className="ml-3 text-gray-500" href="#linkedin">
                        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0} className="w-5 h-5" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
                            <circle cx={4} cy={4} r={2} stroke="none" />
                        </svg>
                    </a>
                </span>
            </div>
        </footer>);
}
