import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCloud, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import TileList from './Tile/TileList';
import { Tile, TileIcon, TileHeading, TileInfo, TileDescription } from "./Tile/Tile";

export default function MHnetEnterpriseVPN() {
    return (<div>
        <section className="text-gray-600 body-font mh-vpn-bg">
            <div className="container xl:py-16 mx-auto">
                <div className="flex flex-col px-8 py-16 justify-center items-left">
                    <div className="w-full lg:w-2/3 md:w-5/6 flex flex-col z-10">
                        <div className="faded-bg-black p-2 sm:text-left text-center">
                            <h1 className="title-font sm:text-3xl xl:text-4xl text-2xl p-2 pb-4 font-bold text-gray-200 tracking-wide">
                                MHnet Enterprise VPN
                            </h1>
                            <p className="p-2 leading-relaxed text-xl text-gray-100 font-normal">
                                Secure Cloud Network Access Solutions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <TileList>
            <Tile>
                <TileIcon>
                    <span className="w-12 h-12 fa-layers fa-fw">
                        <FontAwesomeIcon className="mhIcon" icon={faCloud} />
                        <FontAwesomeIcon className="text-white" icon={faLock} transform="grow-2 down-2" />
                    </span>
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Road Warrior VPN for Private Cloud Network Access
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
            <Tile>
                <TileIcon>
                    <span className="w-12 h-12 fa-layers fa-fw">
                        <FontAwesomeIcon className="mhIcon" icon={faCloud} />
                        <span className="fa-layers-text text-white pt-2 font-bold">VPC</span>
                    </span>
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        VPN Tunnels between Multiple Private Networks (VPC Peering)
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
            <Tile>
                <TileIcon>
                    <span className="mt-2 w-12 h-12 fa-layers fa-fw">
                        <FontAwesomeIcon className="mhIconSmall" rotation="270" icon={faRedoAlt} />
                        <FontAwesomeIcon icon={faLock} />
                    </span>
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        High Availability and Failover of IPSec VPN links
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
        </TileList>
    </div>);
}
