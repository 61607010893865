import React from "react";
import { Link, useHistory } from 'react-router-dom';

export default function Header() {
    const history = useHistory();
    return (
        <header className="text-gray-600 body-font z-20">
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <Link className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" to="/">
                    <img src="/logo_round.svg" alt="Mayan.Host" />
                    <span className="ml-3 text-xl font-semibold">
                        Mayan.Host
                    </span>
                </Link>
                <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 lg:border-l lg:border-gray-400 flex flex-wrap items-center text-base justify-center">
                    <Link className="mr-1 hover:text-gray-900 hover:bg-yellow-100 py-1 px-2 rounded" to="/MH-monitor">
                        MH Monitor
                    </Link>
                    <Link className="mr-1 hover:text-gray-900 hover:bg-yellow-100 py-1 px-2 rounded" to="/MH-security">
                        MH Security
                    </Link>
                    <Link className="mr-1 hover:text-gray-900 hover:bg-yellow-100 py-1 px-2 rounded" to="/MH-ops">
                        MH Ops</Link>
                    <Link className="mr-1 hover:text-gray-900 hover:bg-yellow-100 py-1 px-2 rounded" to="/MH-net-enterprise-vpn">
                        MHnet Enterprise VPN
                    </Link>
                    <Link className="mr-1 hover:text-gray-900 hover:bg-yellow-100 py-1 px-2 rounded" to="/MH-beyond">
                        MH Beyond
                    </Link>
                </nav>
                <button className="inline-flex items-center bg-yellow-200 border-0 py-1 px-4 focus:outline-none hover:bg-yellow-300 rounded text-base mt-4 md:mt-0 text-black"
                    onClick={() => history.push('/contact')}>
                    Contact Us
                </button>
            </div>
        </header>);
}
