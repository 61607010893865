import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faUserShield, faUserSecret, faCog, faHammer, faVial, faSearch, faBug, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import TileList from './Tile/TileList';
import { Tile, TileIcon, TileHeading, TileInfo, TileDescription } from "./Tile/Tile";

export default function MHSecurity() {
    return (
        <div>
            <section className="text-gray-600 body-font mh-security-bg">
                <div className="container xl:py-16 mx-auto">
                    <div className="flex flex-col px-8 py-16 justify-center items-left">
                        <div className="w-full lg:w-2/3 md:w-5/6 flex flex-col z-10">
                            <div className="faded-bg-white p-2 sm:text-left text-center">
                                <h1 className="title-font sm:text-3xl xl:text-4xl text-2xl p-2 pb-4 font-bold text-gray-900 tracking-wide">
                                    MH Security
                                </h1>
                                <p className="p-2 leading-relaxed text-xl text-black font-semibold">
                                    Get multiple layers of security against Threat Actors
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TileList>
                <Tile>
                    <TileIcon>
                        <FontAwesomeIcon className="mhIconSmall" icon={faShieldAlt} />
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            Firewalling
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
                <Tile>
                    <TileIcon>
                        <FontAwesomeIcon className="mhIconSmall" icon={faUserSecret} />
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            Security Auditing
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
                <Tile>
                    <TileIcon>
                        <span className="w-12 h-12 fa-layers fa-fw">
                            <FontAwesomeIcon className="mhIconSmaller" icon={faCog} transform="left-3" />
                            <FontAwesomeIcon icon={faHammer} className="text-yellow-500" transform="grow-7 down-8 right-15" />
                        </span>
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            OS Hardening
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
                <Tile>
                    <TileIcon>
                        <span className="w-12 h-12 fa-layers fa-fw mt-1">
                            <FontAwesomeIcon className="mhIcon" icon={faDesktop} />
                            <FontAwesomeIcon icon={faVial} className="text-yellow-500" transform="grow-7 up-4" />
                        </span>
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            System Integrity Checks
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
                <Tile>
                    <TileIcon>
                        <span className="w-12 h-12 fa-layers fa-fw mt-2">
                            <FontAwesomeIcon className="mhIcon" icon={faSearch} />
                            <FontAwesomeIcon icon={faBug} className="text-yellow-500" transform="grow-1 up-6 left-4" />
                        </span>
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            Vulnerability Scans and Reports
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
                <Tile>
                    <TileIcon>
                        <FontAwesomeIcon className="mhIcon" icon={faUserShield} />
                    </TileIcon>
                    <TileInfo>
                        <TileHeading>
                            Intrusion Detection and Prevention
                        </TileHeading>
                        <TileDescription>

                        </TileDescription>
                    </TileInfo>
                </Tile>
            </TileList>
        </div>);
}
