import React from "react";

export default function TileList(props) {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-20 mx-auto">
                <div className="flex flex-wrap justify-center sm:justify-start xl:mx-5 -mb-10 -mt-4 md:space-y-0 space-y-6">
                    {props.children}
                </div>
            </div>
        </section>);
}