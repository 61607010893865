import { useRef, useState } from "react";
import React from "react";
import axios from "axios";

function ContactUs() {
    const slackWebhookUrl = "https://hooks.slack.com/services/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";

    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const messageRef = useRef(null);

    const [state, setState] = useState({
        isSendSuccess: false,
        message: "",
        headerMessage: ""
    });

    const getMessageStyle = () => {
        var styles = "";
        if (state.isSendSuccess === true && state.message.length > 0) {
            styles = "msgBlock successStyle";
        }
        else if (state.isSendSuccess === false && state.message.length > 0) {
            styles = "msgBlock failedStyle";
        }
        else {
            styles = "hidden";
        }
        console.log(styles);
        return styles;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            "text": `Name: ${nameRef.current.value} \nEmail: ${emailRef.current.value} \nPhone Number: ${phoneNumberRef.current.value} \nMesssage:${messageRef.current.value}`,
        };

        let res = await axios.post(slackWebhookUrl, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                delete headers.post["Content-Type"]
                return data
            }]
        })
        if (res.status === 200) {
            setState({
                isSendSuccess: true,
                headerMessage: "Success",
                message: "Thank you for getting in touch! We will get back to you shortly."
            });
            //clear text boxes on successful submit
            nameRef.current.value = '';
            emailRef.current.value = '';
            phoneNumberRef.current.value = '';
            messageRef.current.value = '';
        } else {
            setState({
                isSendSuccess: false,
                headerMessage: "There was an error",
                message: "Please try again later or reach out to us at our email address hello@mayan.host"
            });
        }
    }
    return (
        <section className="text-gray-600 body-font relative">
            <div className="contact-us mx-auto flex flex-col sm:flex-nowrap flex-wrap ">
                <div className="px-5 py-10 w-full">
                    <form onSubmit={handleSubmit} className="relative md:w-3/5 sm:w-4/5 bg-white flex flex-col w-full md:mt-0 m-auto py-6 px-6 rounded shadow-md" aria-describedby="msgBlock">
                        <h2 className="text-gray-900 text-xl mb-1 font-semibold title-font">
                            Contact Us
                    </h2>
                        <div id="msgBlock" className={`${getMessageStyle()} mt-4 mb-4 `}>
                            <div className="summaryHeader">{state.headerMessage}</div>
                            {state.message}
                        </div>
                        <p className="leading-relaxed mb-5 text-gray-600">
                            Please fill out this quick form and we would love to connect back with you.
                    </p>
                        <div className="relative mb-4">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-800 text-left">Name</label>
                            <input required type="text" ref={nameRef} id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-gray-200 focus:ring-2 focus:ring-gray-400 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-800 text-left">Email</label>
                            <input required type="email" ref={emailRef} id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-gray-200 focus:ring-2 focus:ring-gray-400 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="phone" className="leading-7 text-sm text-gray-800 text-left">Mobile Number</label>
                            <input required inputMode="numeric" ref={phoneNumberRef} pattern="[0-9()+ ]+" type="text" id="phone" name="phone" className="w-full bg-white rounded border border-gray-300 focus:border-gray-200 focus:ring-2 focus:ring-gray-400 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="message" className="leading-7 text-sm text-gray-800 text-left">Message</label>
                            <textarea required id="message" name="message" ref={messageRef} className="w-full bg-white rounded border border-gray-300 focus:border-gray-200 focus:ring-2 focus:ring-gray-400 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                        <button type="submit" className="text-black bg-yellow-300 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-400 rounded text-lg">
                            Send
                        </button>
                    </form>
                </div>
                <div className="bg-gray-200 rounded overflow-hidden p-10 flex items-end justify-start relative mb-10 self-center w-full">
                    <iframe className="absolute inset-0" style={{ filter: 'contrast(1.2) opacity(0.6)' }} title="map" marginHeight={0} marginWidth={0} scrolling="no" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=Brigade_Wisteria&ie=UTF8&t=&z=14&iwloc=B&output=embed" width="100%" height="100%" frameBorder={0} />

                    <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md m-auto">
                        <div className="px-6">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                            <p className="mt-1">D-0026, Ground Floor, Brigade Wisteria,
                            Kanakpura Main Road,
                            </p>
                            <p>
                                Banglore - 560082,
                            Karnataka, India</p>
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">EMAIL</h2>
                            <a href="#email" className="text-indigo-500 leading-relaxed">hello@mayan.host</a>
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                            <p className="leading-relaxed">+91 96692 50192</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default ContactUs;
