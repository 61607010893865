import React from 'react';
import Home from "./home";
import MHMonitor from "./mhMonitor";
import MHSecurity from "./mhSecurity";
import MHOps from "./mhOps";
import MHnetEnterpriseVPN from "./mhnetEnterpriseVPN";
import MHBeyond from "./mhBeyond";
import ContactUs from "./contactUs";
import { Switch, Route } from 'react-router-dom';

const NavTabs = () => {
    return(
        <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/contact" component={ContactUs}></Route>
            <Route exact path="/MH-monitor" component={MHMonitor}></Route>
            <Route exact path="/MH-security" component={MHSecurity}></Route>
            <Route exact path="/MH-ops" component={MHOps}></Route>
            <Route exact path="/MH-net-enterprise-vpn" component={MHnetEnterpriseVPN}></Route>
            <Route exact path="/MH-beyond" component={MHBeyond}></Route>
        </Switch>
    );
}

export default NavTabs;