import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faClipboardList, faChartLine } from '@fortawesome/free-solid-svg-icons';
import TileList from './Tile/TileList';
import { Tile, TileIcon, TileHeading, TileInfo, TileDescription } from "./Tile/Tile";

export default function MHMonitor() {
    return (<div>
        <section className="text-gray-600 body-font mh-monitor-bg">
            <div className="container xl:py-16 mx-auto">
                <div className="flex flex-col px-8 py-16 justify-center">
                    <div className="w-full lg:w-2/3 md:w-5/6 flex flex-col z-10">
                        <div className="faded-bg-white p-2 sm:text-left text-center">
                            <h1 className="title-font sm:text-3xl xl:text-4xl text-2xl p-2 pb-4 font-bold text-gray-900 tracking-wide">
                                MH Monitor Observability
                            </h1>
                            <p className="p-2 leading-relaxed text-xl text-black font-semibold">
                                Get Actionable insights from Metrics, Tracing and Logging
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <TileList>
            <Tile>
                <TileIcon>
                    <FontAwesomeIcon className="mhIconSmall" icon={faDesktop} />
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Monitoring of Websites, Servers and Services
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
            <Tile>
                <TileIcon>
                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-12 h-12" viewBox="0 0 24 24">
                        <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                    </svg>
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Application Performance Monitoring
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
            <Tile>
                <TileIcon>
                    <FontAwesomeIcon className="mhIcon" icon={faClipboardList} />
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Logging for Analytics and Error Reporting
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
            {/*
            <Tile>
                <TileIcon>
                    <FontAwesomeIcon className="mhIcon" icon={faCogs} />
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Anomaly Detection using ML
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
	            */}
            <Tile>
                <TileIcon>
                    <FontAwesomeIcon className="mhIconSmall" icon={faChartLine} />
                </TileIcon>
                <TileInfo>
                    <TileHeading>
                        Synthetic Monitoring of Webapps
                    </TileHeading>
                    <TileDescription>

                    </TileDescription>
                </TileInfo>
            </Tile>
        </TileList>
    </div>);
}
