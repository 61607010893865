import React from "react";

export function Tile(props) {
    return (
        <div className="sm:px-6 py-7 w-full lg:w-1/2 flex sm:flex-row flex-col items-center sm:items-start">
            {props.children}
        </div>
    );
}

export function TileIcon(props) {
    return (
        <div className="w-12 h-12 inline-flex items-center justify-center text-yellow-500 pb-8 sm:pb-0">
            {props.children}
        </div>
    );
}

export function TileInfo(props) {
    return (
        <div className="flex-grow sm:pl-6 sm:text-left text-center">
            {props.children}
        </div>
    );
}

export function TileHeading(props) {
    return (
        <h2 className="text-gray-900 text-lg title-font font-semibold mb-2">
            {props.children}
        </h2>
    );
}

export function TileDescription(props) {
    return (
        <p className="leading-relaxed text-base">
            {props.children}
        </p>
    );
}