import React from "react";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt, faInfinity, faRocket } from '@fortawesome/free-solid-svg-icons';

function Home() {
    const history = useHistory();
    return (<div>
        <section className="text-gray-600 body-font hero-section">
            <div className="container mx-auto flex flex-col px-5 py-16 xl:py-32 justify-center items-center">
                <div className="faded-bg-white w-full md:w-5/6 flex flex-col items-center text-center z-10">
                    <h1 className="title-font sm:text-4xl text-3xl mt-4 mb-4 font-semibold text-gray-900">
                        Compute Made Simple and Reliable
                    </h1>
                    <p className="mb-4 leading-relaxed text-lg text-black font-semibold p-2">
                        Mayan.Host Provisions Tools to Simplify Cloud Operations that Developers can rely on
                    </p>
                </div>
            </div>
        </section>
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                    <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg>
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                            MH Monitor Observability
                        </h2>
                        <p className="leading-relaxed text-base mb-4">
                            Understand latency, traffic, errors and capacity utilisation of your software
                        </p>
                        <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-black bg-yellow-200 border-0 py-1 px-6 focus:outline-none hover:bg-yellow-300 rounded"
                            onClick={() => history.push("/MH-monitor")}>
                            Learn more
                        </button>
                    </div>
                </div>
                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                    <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0">
                        <FontAwesomeIcon className="mhIcon" icon={faLock} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                            MH Security
                        </h2>
                        <p className="leading-relaxed text-base mb-4">
                            Get multiple layers of security in place against Threat Actors.
                        </p>
                        <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-black bg-yellow-200 border-0 py-1 px-6 focus:outline-none hover:bg-yellow-300 rounded"
                            onClick={() => history.push("/MH-security")}>
                            Learn more
                        </button>
                    </div>
                </div>
                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                    <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0">
                        <FontAwesomeIcon className="mhIcon" icon={faInfinity} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                            MH Ops
                        </h2>
                        <p className="leading-relaxed text-base mb-4">
                            Build Resilient, Self-Healing systems that scale
                        </p>
                        <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-black bg-yellow-200 border-0 py-1 px-6 focus:outline-none hover:bg-yellow-300 rounded"
                            onClick={() => history.push("/MH-ops")}>
                            Learn more
                        </button>
                    </div>
                </div>
                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                    <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0">
                        <FontAwesomeIcon className="mhIcon" icon={faShieldAlt} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                            MHnet Enterprise VPN
                        </h2>
                        <p className="leading-relaxed text-base mb-4">
	                    Control your network access at the perimeter
                        </p>
                        <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-black bg-yellow-200 border-0 py-1 px-6 focus:outline-none hover:bg-yellow-300 rounded"
                            onClick={() => history.push("/MH-net-enterprise-vpn")}>
                            Learn more
                        </button>
                    </div>
                </div>
                <div className="flex items-center lg:w-4/5 mx-auto sm:flex-row flex-col">
                    <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-500 flex-shrink-0">
                        <FontAwesomeIcon className="mhIcon" icon={faRocket} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                            MH Beyond
                        </h2>
                        <p className="leading-relaxed text-base mb-4">
	                    <br/>  Zero-trust BeyondCorp service (no VPN) with 2FA, SSO
                        </p>
                        <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-black bg-yellow-200 border-0 py-1 px-6 focus:outline-none hover:bg-yellow-300 rounded"
                            onClick={() => history.push("/MH-beyond")}>
                            Learn more
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>)
}

export default Home;
